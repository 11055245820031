import mockup from "../images/iphone_mockup.png";
import integrated_storytelling from "../images/integrated_storytelling.svg";
import community_voting from "../images/community_voting.svg";
import archive from "../images/archive.svg";
import social_style from "../images/social_style.svg";

const features = [
  {
    name: "Storytelling integrato:",
    description:
      "Tale permette agli utenti di creare una storia partendo da un incipit fornito da un utente e di svilupparla insieme alla community. Ogni contributo aggiunge un nuovo tassello alla trama, creando una storia unica e coinvolgente.",
    icon: integrated_storytelling,
  },
  {
    name: "Votazione della community:",
    description:
      "Durante uno slot di tempo dedicato, la community ha la possibilità di votare quale contributo aggiungere successivamente alla storia. Questo processo garantisce un coinvolgimento attivo da parte degli utenti e favorisce la creazione di storie di alta qualità.",
    icon: community_voting,
  },
  {
    name: "Archivio di storie completo:",
    description:
      "Le storie completate sono accessibili a tutti gli utenti e sono organizzate per categorie, consentendo agli utenti di  esplorare una vasta gamma di narrazioni e scoprire nuovi autori e generi",
    icon: archive,
  },
  {
    name: "Social network style:",
    description:
      "Tale è più di una semplice piattaforma di storytelling; è un vero e proprio social network dedicato alla creatività e all'immaginazione. Gli utenti possono interagire con le storie attraverso mi piace, commenti e condivisioni, rendendo l'esperienza di lettura più coinvolgente e gratificante.",
    icon: social_style,
  },
];

export default function ProductComponent() {
  return (
    <div id="product" className="mt-24 md:mt-48 overflow-hidden bg-white">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="lg:text-center">
          <h2 className="text-palette-primary text-3xl font-bold">
            La piattaforma
          </h2>
          <hr className="bg-palette-primary mt-2 h-0.5 w-1/2 sm:w-1/4 mx-auto"></hr>
          <p className="mt-12 text-2xl md:text-3xl leading-8 font-bold text-gray-900">
            In Tale immaginiamo un futuro in cui lo storytelling diventa un
            mezzo potente per connettere le persone di tutto il mondo.
          </p>
        </div>
        <div className="mx-auto mt-16 md:mt-32 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <p className="mt-2 text-3xl font-bold text-left tracking-tight text-palette-primary sm:text-4xl">
                Un social innovativo
              </p>
              <p className="mt-6 text-lg text-left font-medium leading-8">
                Tale unisce la narrativa di gruppo allo stile di un social
                network, permettendo di creare una community forte e proveniente
                da ogni parte del mondo.
              </p>
              <ul className="mt-10 max-w-xl space-y-8 text-base text-left leading-7 lg:max-w-none">
                {features.map((feature) => (
                  <li key={feature.name} className="flex gap-x-3">
                    <img
                      className="mt-1 h-[50px] flex-none text-indigo-600"
                      aria-hidden="true"
                      src={feature.icon}
                    />
                    <span>
                      <strong className="font-semibold text-gray-900">
                        {feature.name}
                      </strong>{" "}
                      {feature.description}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <img
            src={mockup}
            alt="Product screenshot"
            className="w-[28rem] mx-auto max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
            width={2432}
            height={1442}
          />
        </div>
      </div>
    </div>
  );
}
