import RiveComponent from "@rive-app/react-canvas";
import book_anim from "../animations/closed_book.riv";

export default function HeroComponent() {
  return (
    <div id="hero" className="w-full mx-auto py-12 bg-palette-primary">
      <div className="max-w-7xl mx-auto my-auto grid grid-cols-1 sm:grid-cols-2 px-10">
        <div className="sm:col-span-1 sm:col-start-1 w-full flex flex-col z-20 my-auto">
          <div className="typewriter">
            <h1 className="max-w-7xl  text-start text-7xl text-white font-bold">
              Crafting stories, together
            </h1>
          </div>
          <p className="text-lg  text-gray-100 text-start py-8">
            Tale è una piattaforma social innovativa progettata per dare vita
            alla creatività collettiva attraverso lo storytelling. Nasce dalla
            nostra passione per le storie e dalla convinzione che ognuno abbia
            una storia da raccontare.
          </p>
          <div className="flex mt-8 ">
            <a
              href="#download"
              className=" py-2 px-4 rounded-lg bg-white text-palette-primary text-md mr-4 hover:bg-blue-950 hover:text-white"
            >
              Download
            </a>
            <a
              href="#about"
              className=" py-2 px-4 rounded-lg bg-blue-950  text-white hover:bg-white hover:text-palette-primary text-md"
            >
              Leggi di più
            </a>
          </div>
        </div>
        <div className="w-[300px] h-[300px] md:w-[400px] md:h-[400px] lg:w-[600px] lg:h-[600px] sm:col-span-1 mx-auto my-auto">
          <RiveComponent src={book_anim} stateMachines="Animation" />
        </div>
      </div>
    </div>
  );
}
