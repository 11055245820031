import React from "react";
import "./App.css";
import HeroComponent from "./components/HeroComponent";
import AboutComponent from "./components/AboutComponent";
import FeaturesComponent from "./components/FeaturesComponent";
import HeaderComponent from "./components/HeaderComponent";
import ProductComponent from "./components/ProductComponent";
import DownloadComponent from "./components/DownloadComponent";
import FooterComponent from "./components/FooterComponent";

function App() {
  return (
    <div className="App">
      <HeaderComponent />
      <HeroComponent />
      <AboutComponent />
      <ProductComponent />
      <DownloadComponent />
      <FooterComponent />
    </div>
  );
}

export default App;
