import mission_illustration from "../images/mission_illustration.png";
import vision_illustration from "../images/vision_illustration.jpg";

export default function AboutComponent() {
  return (
    <section id="about" className="mt-24 md:mt-48 max-w-7xl xl:w-1200 m-auto">
      <div className="space-y-32">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-palette-primary text-3xl font-bold">Chi siamo</h2>
          <hr className="bg-palette-primary mt-2 h-0.5 w-1/2 sm:w-1/4 mx-auto"></hr>
          <p className="mt-12 text-2xl md:text-3xl leading-8 font-bold text-gray-900">
            Con Tale vogliamo fornire agli utenti uno spazio dove possono
            iniziare una storia e vederla crescere attraverso il contributo
            della community.
          </p>
        </div>
      </div>
      <div className="space-y-8 px-8 md:space-y-0 text-center md:text-left md:space-x-16 md:justify-center md:flex md:items-center ">
        <div className="w-full mt-24 md:w-2/3 grid grid-cols-1 md:grid-cols-2 gap-y-24">
          <div className="my-auto">
            <h3 className="font-bold text-2xl">La nostra mission</h3>
            <p className="mt-2 font-medium">
              Vogliamo ispirare e connettere le persone attraverso la
              condivisione di storie, incoraggiando la creatività e
              l'immaginazione in un ambiente inclusivo e divertente, utilizzando
              le funzionalitá di un social network.
            </p>
          </div>

          <div className="mx-auto my-auto w-1/2 md:w-1/2">
            <img
              className="md:w-80 mx-auto my-auto"
              src={mission_illustration}
            />
          </div>

          <div className="mx-auto my-auto w-1/2 md:w-1/2 order-last md:order-none">
            <img className="md:w-80 mx-auto" src={vision_illustration} />
          </div>
          <div className="my-auto">
            <h3 className="font-bold text-2xl">La nostra vision</h3>
            <p className="mt-2 font-medium">
              Crediamo in un mondo in cui lo storytelling diventa un'esperienza
              condivisa, in cui le persone si uniscono per costruire storie
              uniche e coinvolgenti. Vogliamo dare voce a tutti, dando loro la
              possibilità di esprimersi liberamente e di scoprire nuove storie
              in un ambiente accogliente e stimolante.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
